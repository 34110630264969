@import "./reset.scss";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~video.js/dist/video-js.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
  --color-foreground: hsla(0, 0%, 100%, 1);
  --color-text-50: hsla(0, 0%, 62%, 1);
  --color-text-100: hsl(230, 13%, 27%);
  --color-text-200: hsla(236, 8%, 35%, 1);
  --color-sub-text: hsla(210, 16%, 59%, 1);

  --color-brand: hsla(196, 100%, 47%, 1);

  --color-disabled: hsla(0, 0%, 75%, 1);
  --color-text-link: hsla(202, 53%, 48%, 1);

  --color-body-background: hsla(210, 20%, 96%, 1);
  --color-textarea-border: hsla(225, 4%, 79%, 1);
  --font-heading: "Montserrat";
  --font-body: "Montserrat";
  --color-button-border: hsla(210, 17%, 91%, 1);
  --color-edit-background: hsla(240, 4%, 95%, 1);
  --color-required: hsl(0, 100%, 50%);
  --color-note-banner: hsl(50, 96%, 89%);
}

html,
body,
main {
  height: 100%;
}

body {
  background-color: var(--color-body-background);
}
strong {
  font-weight: 600;
}

h1,
h2,
h3,
h5,
a,
textarea,
button {
  font-family: var(--font-heading, "sans-serif");
}

p,
li {
  font-family: var(--font-body, "sans-serif");
  font-size: 16px;
  line-height: 1.4;
}

a {
  color: var(--color-text-link);
  text-decoration: none;
}

button {
  border: none;
  background: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  font-weight: 500;
  &.primary {
    background-color: var(--color-brand);
    color: white;
    &:hover {
      box-shadow: 0px 1px 4px hsla(0, 0%, 27%, 0.5);
    }
    &[disabled] {
      background-color: var(--color-disabled);
    }
  }
  &.stroked {
    background-color: var(--color-foreground);
    border: 1px solid var(--color-button-border);

    &[disabled] {
      cursor: not-allowed;
      background-color: var(--color-disabled);
      color: white;
      border: none;
    }
  }
  &.stroked-blue {
    background-color: var(--color-foreground);
    border: 1px solid var(--color-brand);
    color: var(--color-brand);
    :disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: var(--color-disabled);
    color: white;
    border: none;
  }
}
// FIXME : this has to be done in a better way
.line-height-2 {
  line-height: 1rem;
}

// TODO: Potential Dark Mode
html.dark {
  --color-foreground: hsl(0, 0%, 17%);
  --color-text-100: hsl(0, 0%, 92%);
  --color-body-background: hsla(0, 0%, 45%, 1);
}

.cdk-drag-preview {
  background-color: rgb(208, 209, 207);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  opacity: 0.7;
}

.isc-times-circle {
  cursor: pointer;
}

.edited-icon {
  background: #fff9de;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.required-asterisk {
  color: var(--color-required);
}

input {
  padding: 4px 8px;
}

.isc-reorder-solid {
  cursor: grab;
  &:hover {
    background: #f2f2f3;
  }
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.bg-foreground {
  background: var(--color-foreground);
}
.toast-message {
  font-family: var(--font-heading, "Montserrat");
  line-height: 1.5rem;
}

.error {
  font-size: 12px;
  line-height: 18px;
  color: #e74d3c;
  font-weight: 600;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  font-weight: 400;
}
